;(function () {
  const fileFieldApp = function (hintElementId, maxHintLength) {
    return {
      hintElement: document.getElementById(hintElementId),
      onFileFieldChange: function (e, $dispatch) {
        const files = e.target.files

        let filename = ''

        if (files.length == 1) {
          filename = files[0].name
          filename =
            filename.length <= maxHintLength
              ? filename
              : filename.slice(0, maxHintLength - 13) +
                '...' +
                filename.slice(filename.length - 10, filename.length)
        } else {
          filename = `${files.length} selected files...`
        }

        this.hintElement.innerHTML = filename

        $dispatch('file-selected', filename)
      },
    }
  }

  if (typeof window !== 'undefined') {
    window.fileFieldApp = fileFieldApp
  }

  return fileFieldApp
})()
