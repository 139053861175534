(function () {
  const commonResponsesApp = function(name, companyId) {
    return {
      name: name,
      companyId: companyId,
      dispatch: null,
      commonResponses: [],
      selectedCommonResponse: null,

      init: function($dispatch) {
        this.dispatch = $dispatch
        const __this = this

        fetch(`/appraisal_checking/companies/${this.companyId}/common_responses.json`)
          .then((response) => response.json())
          .then((data) => {
            __this.commonResponses = data
          })
      },

      useCommonResponse: function(responseId) {
        let content = ''

        if (responseId) {
          const response = this.commonResponses.find((r) => r.id === responseId)
          content = response?.response || ''
        }
        this.dispatch('common-response-selected', { name: this.name, content })
      },
    }
  }

  if (typeof window !== "undefined") {
    window.commonResponsesApp = commonResponsesApp
  }

  return commonResponsesApp
})()
