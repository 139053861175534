import _ from 'lodash';

(function () {
  const selectAutocompleteApp = function (options = {}) {
    const primaryKey = options?.primaryKey || 'primary',
          secondaryKey = options?.secondaryKey || '',
          url = options?.url,
          selectedTags = normalize(options?.selectedTags || []),
          resultKey = options?.resultKey,
          maxItems = options?.maxItems || 50,
          multipleClauses = options?.multipleClauses,
          selectedClause = options?.selectedClause,
          mode = options?.mode || null,
          closeOnSelect = options?.closeOnSelect || false;

    function normalize (collection) {
      return collection.map(entry => ({ ...entry, value: entry.id }))
    }

    function dropdownItemTemplate(tagData) {
      delete tagData.id;

      return `
        <div ${this.getAttributes(tagData)}
             tabindex="0"
             role="option"
             class="select-autocomplete__dropdown-item tagify__dropdown__item"
        >
          <span class="dropdown-item__primary">${tagData[primaryKey]}</span>
          ${secondaryKey &&
            `<span class="dropdown-item__secondary">${tagData[secondaryKey] || 'N/A'}</span>`}
        </div>
      `
    }

    return {
      initialize: function(autocompleteInput) {
        const _this = this;

        this.tagify = new Tagify(autocompleteInput, {
          tagTextProp: primaryKey,
          enforceWhitelist: true,
          skipInvalid: true,
          dropdown: {
            closeOnSelect: closeOnSelect,
            enabled: 1,
            maxItems: maxItems,
            searchKeys: [primaryKey, secondaryKey]
          },
          templates: {
            dropdownItem: dropdownItemTemplate
          },
          whitelist: selectedTags,
          mode: mode
        });

        this.tagify.addTags(selectedTags);
        this.tagify.on('input', _.debounce(this.onInput.bind(this), 300));
      },
      tagify: null,
      selectedTags,
      selectedClause,
      multipleClauses,
      mode,
      closeOnSelect,
      clauseQuery: function(objectName, name) {
        if (this.mode === 'select') {
          return `${objectName}[${name}]`;
        } else if (this.multipleClauses) {
          return `${objectName}[${name}_${this.selectedClause}][]`;
        } else {
          return `${objectName}[${name}][]`;
        }
      },
      onInput: function(e) {
        let tagify = this.tagify;

        tagify.loading(true);

        let formattedUrl = url.replace('{value}', e.detail.value);

        fetch(formattedUrl, { method: 'GET' })
          .then(response => response.json())
          .then(whitelist => {
            if (resultKey) { whitelist = whitelist[resultKey] }

            tagify.settings.whitelist = [...normalize(whitelist), ...tagify.value];
            tagify.loading(false).dropdown.show.call(tagify, e.detail.value);
          });
      }
    }
  }

  if (typeof window !== "undefined") {
    window.selectAutocompleteApp = selectAutocompleteApp;
  }

  return selectAutocompleteApp;
})();
