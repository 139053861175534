(function () {
  const eventConditionsApp = function () {
    return {
      eventConditionField: function (element, event) {
        let startPos = element.selectionStart;
        let endPos = element.selectionEnd;

        insertValue(element, event, startPos, endPos);
      }
    };
  };

  function insertValue(element, event, startPos, endPos) {
    const content = element.value;
    const eventConditionValue = event.target.value;

    event.target.value = '';

    element.value = content.substring(0, startPos) +
                    eventConditionValue +
                    content.substring(endPos, content.length);
  }

  if (typeof window !== "undefined") {
    window.eventConditionsApp = eventConditionsApp;
  }

  return eventConditionsApp;
})();
