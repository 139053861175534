(function () {
  const tabApp = function () {
    return {
      loading: false,
      selected: null,
      subSelected: null,
      select: function (value, subValue = null, event = null) {
        if (this.selected === value && event !== null) event.preventDefault()

        if (this.selected !== value) this.loading = true

        this.subSelected = subValue
        this.selected = value;

        if (subValue !== null) {
          setTimeout(() => document.getElementById(`${subValue}-anchor`).scrollIntoView({ behavior: 'smooth' }), 400)

          return
        }
      }
    };
  };

  if (typeof window !== "undefined") {
    window.tabApp = tabApp;
  }

  return tabApp;
})();
