import Chart from 'chart.js/auto';

(function () {
  const vendorStatisticsApp = (vendor_id) => {
    function drawChart(data) {
      return new Chart(
        document.getElementById('profileStatistics'),
        {
          type: 'bar',
          data: {
            labels: [''],
            datasets: data.statistics.map((item) => {
              return {
                label: item.label,
                data: [item.value],
                backgroundColor: item.color,
                borderRadius: 8,
                datalabels: {
                  formatter: function(value) {
                    return `${value}%`;
                  },
                  anchor: 'end',
                  align: 'top',
                  color: 'rgb(107, 114, 128)'
                }
              }
            })
          },
          plugins: [ChartDataLabels],
          options: {
            plugins: {
              legend: {
                position: 'bottom',
                labels: {
                  usePointStyle: true,
                  boxHeight: 7,
                  pointStyle: 'circle',
                }
              },
            },
            scales: {
              y: {
                ticks: {
                  min: 0,
                  max: 125,
                  stepSize: 25,
                  callback: (value) => {
                    return value + '%';
                  }
                },
                grid: {
                  drawBorder: false,
                  drawTicks: false,
                }
              },
              x: {
                grid: {
                  drawOnChartArea: false,
                  drawBorder: false,
                  drawTicks: false,
                }
              }
            }
          }
        }
      )
    }
    return {
      chart: null,
      totalAssignments: 0,
      totalAccepted: 0,
      getStatistics: function (startDate = null, endDate = null) {
        let customRange = ''

        if (startDate != null && endDate != null) {
          customRange = `?start_date=${startDate}&end_date=${endDate}`
        }

        fetch(`/ordering/vendors/${vendor_id}/profile_statistics${customRange}`)
              .then((response) => response.json())
              .then((result) => {
                if (this.chart != null) this.chart.destroy();
                this.totalAssignments = result.total_assignments;
                this.totalAccepted = result.total_accepted;
                this.chart = drawChart(result)
              });
      },
    };
  }

  if (typeof window !== 'undefined') {
    window.vendorStatisticsApp = vendorStatisticsApp;
  }

  return vendorStatisticsApp;
})();
