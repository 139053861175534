;(function () {
  const vendorProductFeeTableApp = function (vendorId, productFeeTableId) {
    return {
      updatedProducts: {},
      handleSubmit: function () {
        const params = this.formatUpdatedProductsToParams()
        const body = { product_fee_table: { product_fees_attributes: params } }
        const path = `/paying/vendors/${vendorId}/product_fee_tables/${productFeeTableId}`

        new FetchRequest('patch', path, {
          body,
          responseKind: 'turbo-stream',
        }).perform()
      },
      formatUpdatedProductsToParams: function () {
        return Object.values(this.updatedProducts).map((product) => {
          const vendorFee = parseFloat(product.vendorFee)

          return {
            id: product.productFeeId,
            product_id: product.productId,
            vendor_fee: vendorFee,
            _destroy: vendorFee === 0,
          }
        })
      },
    }
  }

  if (typeof window !== 'undefined') {
    window.vendorProductFeeTableApp = vendorProductFeeTableApp
  }

  return vendorProductFeeTableApp
})()
