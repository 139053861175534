(function () {
  const filterPlace = place => type => {
    const component = place.address_components.filter(comp => comp.types[0] === type);

    return (component && component[0] && component[0].short_name) || undefined;
  };

  const placeInputApp = function (placeInputId, address = {}) {
    let $dispatch;
    const placeInput = document.getElementById(placeInputId);

    const autocomplete = new google.maps.places.Autocomplete(placeInput, {
      type: ['geocode'],
    });

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();

      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();

      const filterComponent = filterPlace(place);

      const streetNumber = filterComponent('street_number');
      const route = filterComponent('route');
      const street = streetNumber && route ? `${streetNumber} ${route}` : route;
      const city = filterComponent('locality') || filterComponent('administrative_area_level_2');
      const county =
        filterComponent('sublocality_level_1') || filterComponent('administrative_area_level_2');
      const state = filterComponent('administrative_area_level_1');
      const zip = filterComponent('postal_code');
      const country = filterComponent('country') || 'US';

      $dispatch('address-changed',
                { street, city, county, state, zip, country, latitude, longitude });
    });

    return {
      address,
      fullAddress: function() {
        let address = this.address

        Object.keys(address).forEach(function(key) {
          if (!address[key]) { address[key] = '' }

          address[key] = new String(address[key]).replace(',', ' ').replace(/\s+/g,' ').trim()
        })

        const { street, city, county, state, zip, country } = address

        if (!street && !city && !county && !state && !zip) return ''

        return `${street}, ${city}, ${county}, ${state} ${zip}, ${country || 'US'}`
      },
      setDispatch: function (dispatch, refs = null) {
        if(refs) this.refs = refs;
        $dispatch = dispatch;
      },
      updateAddress: function (address) {
        if (this.refs) {
          let stateId = ''
          for (const key in this.refs.state_select.options) {
            if(this.refs.state_select.options[key]?.text === address.state) {
              stateId = this.refs.state_select.options[key].value
            }
          }
          this.refs.state_select.value = stateId
        }
        this.address = address;
      },
      clearAddress: function () {
        this.address = {};
      },
    };
  };

  if (typeof window !== "undefined") {
    window.placeInputApp = placeInputApp;
  }

  return placeInputApp;
})();
