(function () {
  const popoutApp = function (records) {
    return {
      opened: true,
      minimized: false,
      dismiss: function() {
        this.opened = false;
      }
    };
  };

  if (typeof window !== 'undefined') window.popoutApp = popoutApp;

  return popoutApp;
})();
