(function () {
  const tableFormApp = function (records) {
    return {
      records: records,
      addNewRow(index) {
        if (index !== this.records.length - 1) { return }

        this.records.push({});
      }
    };
  };

  if (typeof window !== 'undefined') window.tableFormApp = tableFormApp;

  return tableFormApp;
})();
