;(function () {
  const autoAssignmentSettings = function (
    clientSetting,
    preferredVendorsSize
  ) {
    const autoAssignConfig = clientSetting.auto_assign_config

    return {
      autoAssignEnabled: clientSetting.auto_assign_enabled,
      preferredVendorsEnabled: autoAssignConfig.preferred_vendors,
      assignPoolsEnabled: autoAssignConfig.assign_pools_enabled,
      payingValidation: {},
      opened: false,
      showWarnings: false,
      fetchPayingValidations: function () {
        fetch(
          `/paying/companies/${clientSetting.company_id}/clients/${clientSetting.client_id}/fee_table`
        )
          .then((response) => response.json())
          .then((response) => (this.payingValidation = response))
          .then((_) => (this.showWarnings = true))
      },
      orderingErrors: function () {
        let errors = []

        if (!this.autoAssignEnabled) return errors

        if (this.preferredVendorsEnabled && !preferredVendorsSize) {
          errors.push('preferred_vendors')
        }

        return errors
      },
      payingErrors: function () {
        let errors = []

        if (!this.autoAssignEnabled) return errors

        if (!this.payingValidation.product_fee_table_id) {
          errors.push('missing_product_fee')
        }
        if (
          !this.payingValidation.vendor_fees?.filter((number) =>
            Boolean(parseFloat(number))
          ).length
        ) {
          errors.push('missing_vendor_fees')
        }

        return errors
      },
    }
  }

  if (typeof window !== 'undefined') {
    window.autoAssignmentSettings = autoAssignmentSettings
  }

  return autoAssignmentSettings
})()
