(function () {
  const autocomplete = () => {
    return {
      showResults: false,
      selected: null,
      vendorScope: 'all',
      params: null,
      total: 0,
      vendors: [],
      vendorNotes: [],
      showVendorNotes: false,
      loading: false,
      searchLoading: false,
      selectResult: function (resultId, input) {
        input.value = resultId;
        this.selected = resultId;
      },
      getUri: function (value = '', order_id = '') {
        let uri = '/ordering/vendors.json?q[name_cont]=' + value +
                  '&order_id=' + order_id;

        if (this.vendorScope) {
          uri += '&strategy=' + this.vendorScope;
        }

        if (this.params) {
          uri += '&params=' + this.params;
        }

        return uri;
      },
      fetchResults: function (query, order_id) {
        let __this = this;

        this.showResults = true;

        return fetch(this.getUri(query, order_id))
          .then(response => response.json())
          .then(result => {
            __this.vendors = result.vendors;
            __this.total = result.pagination.total;
          });
      },
      fetchVendorNotes: function (vendorId, serviceType = null) {
        let __this = this;
        let uri = '/ordering/vendors/' + vendorId + '/notes.json';

        if (serviceType) { uri = uri + '?service_type=' + serviceType };

        return fetch(uri)
          .then(response => response.json())
          .then(result => {
            __this.vendorNotes = result.vendor_notes;

            __this.showVendorNotes = (__this.vendorNotes.length > 0);
          })
      }
    };
  };

  if (typeof window !== "undefined") {
    window.vendorsAutocompleteApp = autocomplete;
  }

  return vendorsAutocompleteApp;
})();
