(function () {
  const customSelectApp = function (selected, { options }) {
    return {
      opened: false,
      open: function () {
        this.opened = true;
      },
      close: function () {
        this.opened = false;
      },
      options,
      selected: selected,
      select: function (index) {
        this.selected = index;
      }
    };
  };

  if (typeof window !== "undefined") {
    window.customSelectApp = customSelectApp;
  }

  return customSelectApp;
})();
