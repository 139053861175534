;(function () {
  const coverageAreaRadiusApp = function (initialAddress = {}) {
    const buildFullAddress = (address) => {

      const values = Object.values(address).filter((v) => Boolean(v))

      if (!values.length) return ''

      const fullAddress = address.full_address
        ? address.full_address
        : `${address.street}, ${address.city}, ${address.state}, ${address.zip}`

      return fullAddress
    }

    return {
      fullAddress: buildFullAddress(initialAddress),
      stateAcronym: initialAddress.state_acronym,
      radius: initialAddress.radius,
      latitude: initialAddress.latitude,
      longitude: initialAddress.longitude,
      assignAddressAttrs: function (address) {
        const fullAddress = buildFullAddress(address)

        this.fullAddress = fullAddress
        this.stateAcronym = address.state
        this.latitude = address.latitude
        this.longitude = address.longitude
      },
      setReverseAddressToCoverageAreaName: function () {
        const geocoder = new google.maps.Geocoder()
        const latlng = { lat: this.latitude, lng: this.longitude }
        const coverageAreaNameField = document.getElementById(
          'coverage_area_coverage_name'
        )

        geocoder
          .geocode({ location: latlng })
          .then((response) => {
            const result = response.results[0].formatted_address

            if (result) coverageAreaNameField.value = result
          })
          .catch((e) => console.log(`Geocoder failed due to: ${e}`))
      },
      setCoords: function (eventDetail) {
        this.latitude = eventDetail.lat
        this.longitude = eventDetail.lng
      },
      setRadius: function (eventDetail) {
        this.radius = eventDetail
      },
    }
  }

  if (typeof window !== 'undefined') {
    window.coverageAreaRadiusApp = coverageAreaRadiusApp
  }

  return coverageAreaRadiusApp
})()
