;(function () {
  const radiusMapViewApp = function (
    wrapperId,
    $dispatch,
    { radius, latitude, longitude }
  ) {
    return {
      init: function () {
        this.dispatch = $dispatch
        this.center = { lat: parseFloat(latitude), lng: parseFloat(longitude) }
        this.wrapper = document.getElementById(wrapperId)
        this.map = new google.maps.Map(this.wrapper, {
          center: this.center,
          zoom: this.defineZoom(),
        })
        this.marker = this.newMarker(this.center)
        this.circle = new google.maps.Circle({
          strokeColor: '#93C5FD',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#93C5FD',
          fillOpacity: 0.35,
          map: this.map,
          center: this.center,
          radius: this.toMeters(radius),
          editable: true,
        })

        google.maps.event.addListener(
          this.circle,
          'center_changed',
          this.onCenterChanged.bind(this)
        )

        google.maps.event.addListener(
          this.circle,
          'radius_changed',
          this.onRadiusChanged.bind(this)
        )
      },
      onCenterChanged: function () {
        const newCenter = this.circle.getCenter()
        const newCoords = { lat: newCenter.lat(), lng: newCenter.lng() }

        this.marker.setMap(null)
        this.marker = this.newMarker(newCoords)
        this.dispatch('center-changed', newCoords)
      },
      onRadiusChanged: function () {
        const newRadius = this.circle.getRadius()

        this.dispatch('radius-changed', this.toMiles(newRadius))
      },
      newMarker: function (position, title = 'You are here') {
        return new google.maps.Marker({ map: this.map, position, title })
      },
      defineZoom: () => {
        const zoomOpts = { close: 14, medium: 11, far: 8, veryFar: 6 }

        if (radius < 1) return zoomOpts.close
        if (radius < 10) return zoomOpts.medium
        if (radius < 70) return zoomOpts.far

        return zoomOpts.veryFar
      },
      toMeters: (miles) => parseFloat(miles) * 1609.34,
      toMiles: (meters) => (parseFloat(meters) / 1609.34).toFixed(2),
    }
  }

  if (typeof window !== 'undefined') {
    window.radiusMapViewApp = radiusMapViewApp
  }

  return radiusMapViewApp
})()
