(function () {
  const mergeFieldsApp = function () {
    return {
      mergeTrixField: function (element, event) {
        element.editor.insertString(event.target.value);
        event.target.value = '';
      },
      mergeField: function (element, event) {
        let startPos = element.selectionStart;
        let endPos = element.selectionEnd;

        insertValue(element, event, startPos, endPos);
      }
    };
  };

  function insertValue(element, event, startPos, endPos) {
    const content = element.value;
    const mergeFieldValue = event.target.value;

    event.target.value = '';

    element.value = content.substring(0, startPos) +
                    mergeFieldValue +
                    content.substring(endPos, content.length);
  }

  if (typeof window !== "undefined") {
    window.mergeFieldsApp = mergeFieldsApp;
  }

  return mergeFieldsApp;
})();
