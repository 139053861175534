(function () {
  const nestedDynamicForm = function (attributes) {
    return {
      fields: [],
      addNewField() {
        this.fields.push(attrsToAdd(attributes));
      },
      removeField(index) {
        this.fields[index].destroy = true
      },
      labels(index) {
        return this.fields.length && index === 0;
      },
      concatAttrsToPlaceholder(first_attr, second_attr, empty) {
        if (first_attr || second_attr) {
          return `${first_attr.toUpperCase()} ${second_attr.replace(/_/g, ' ')}`;
        } else {
          return empty;
        }
      },
    };
  };

  function attrsToAdd(attributes) {
    return attributes.reduce((hash, attribute) => ({ ...hash, [attribute]: '' }), {});
  }

  if (typeof window !== 'undefined') window.nestedDynamicForm = nestedDynamicForm;

  return nestedDynamicForm;
})();
