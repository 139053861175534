(function () {
  const newPaymentApp = function (closedInvoiceInvoices, searchPath) {
    function matchFilterInput(el) {
      return el.name.match(/^\w+\[q\]\[(\w+)\]$/)
    }

    return {
      closedInvoiceInvoices,
      closedInvoiceInvoicesMap: closedInvoiceInvoices.reduce((acc, invoice) => {
        acc[invoice.id] = invoice
        return acc
      }, {}),
      closedInvoiceInvoiceIds: closedInvoiceInvoices.map(i => i.id),
      selectedClosedInvoiceInvoiceIds: closedInvoiceInvoices.map(i => i.id),
      filtersOpen: false,
      getInvoiceId: function (id) {
        return this.closedInvoiceInvoicesMap[id].invoice_id
      },
      getTotal: function (id) {
        return this.closedInvoiceInvoicesMap[id].total
      },
      setTotal: function (id, total) {
        const invoice = this.closedInvoiceInvoices.find(i => i.id === id)
        invoice.total = total.replace(/[^0-9.]/g, '')
      },
      total: function () {
        return this.closedInvoiceInvoices.reduce((acc, invoice) => {
          if (this.selectedClosedInvoiceInvoiceIds.includes(invoice.id)) {
            return acc + parseFloat(invoice.total || 0)
          } else {
            return acc
          }
        }, 0).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
      },
      durties: {},
      durty: function () {
        return Object.values(this.durties).includes(true)
      },
      search: function ($dispatch) {
        const params = { q: {} }

        document.querySelectorAll('input, select').forEach(el => {
          const matched = matchFilterInput(el)
          if (matched && matched[1] && el.value && el.value !== '') {
            params.q[matched[1]] = el.value
          }
        })

        const query = qs.stringify(params)

        document
          .getElementById('closed_invoice_invoices_table')
          .setAttribute('src', `${searchPath}?${query}`)

        $dispatch('dismiss-drawer-filter')
      },
      clearFilters: function ($dispatch) {
        document.querySelectorAll('input, select').forEach(el => {
          if (!matchFilterInput(el)) return

          el.value = ''
          this.durties[el.name] = false
        })

        this.search($dispatch)
      },
      toggleSelection: function (e) {
        const invoiceId = e.target.dataset.id

        if (e.target.checked) {
          this.selectedClosedInvoiceInvoiceIds = [...this.selectedClosedInvoiceInvoiceIds, invoiceId]
        } else {
          this.selectedClosedInvoiceInvoiceIds =
            this.selectedClosedInvoiceInvoiceIds.filter(id => id !== invoiceId)
        }

        if (this.selectedClosedInvoiceInvoiceIds.length === this.closedInvoiceInvoiceIds.length) {
          document.querySelector('#toggle-all').checked = true
        } else {
          document.querySelector('#toggle-all').checked = false
        }
      },
      toggleAllSelection: function (e) {
        if (e.target.checked) {
          this.selectedClosedInvoiceInvoiceIds = [...this.closedInvoiceInvoiceIds]
          document.querySelectorAll('.invoice-checkbox').forEach(el => el.checked = true)
        } else {
          this.selectedClosedInvoiceInvoiceIds = []
          document.querySelectorAll('.invoice-checkbox').forEach(el => el.checked = false)
        }
      },
      init: function (form, $dispatch) {
        form.querySelectorAll('input[type=text], select').forEach(el => {
          el.addEventListener('keydown', (e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              this.search($dispatch)
            }
          })

          if (!matchFilterInput(el) || el.dataset.skipDirty) return

          el.addEventListener('change', () => {
            if (el.value === '') {
              this.durties[el.name] = false
            } else {
              this.durties[el.name] = true
            }
          })
        })

        setTimeout(() => {
          document.querySelectorAll('.invoice-checkbox').forEach(el => {
            el.checked = true
          })
        }, 100)
      }
    }
  };

  if (typeof window !== "undefined") {
    window.newPaymentApp = newPaymentApp;
  }

  return newPaymentApp;
})();
