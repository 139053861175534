(function () {
  const anchorMenuApp = function (id, topLimit = 200) {
    return {
      setListener: function () {
        var menu = document.getElementById(id)

        document.getElementsByTagName('main')[0].onscroll = onScroll;
        menu.querySelectorAll('.menu__item-title')[0].classList.add("active");

        function onScroll() {
          var removeActiveClass = function (elements) {
            for (var i = 0; i < elements.length; ++i) {
              elements[i].classList.remove('active');
            }
          }
          var anchors = menu.querySelectorAll('.menu__item-title');
          var previousRefElement = null;

          for (var i = 0; i < anchors.length; ++i) {
            var currentRefElement = document.getElementById(anchors[i].getAttribute('data-element-id'));
            var currentRefElementTop = currentRefElement.getBoundingClientRect().top;

            if (currentRefElementTop <= topLimit) {
              previousRefElement = anchors[i];

              if (i == anchors.length - 1) {
                removeActiveClass(anchors);
                anchors[i].classList.add("active");
              }
            } else {
              removeActiveClass(anchors);

              previousRefElement.classList.add("active");

              break;
            }
          }
        }
      }
    };
  };

  if (typeof window !== "undefined") {
    window.anchorMenuApp = anchorMenuApp;
  }

  return anchorMenuApp;
})();
