(function () {
  const vendorLicenseApp = function (license) {
    return {
      filled: license.number?.length,
      selectedLicense: {
        licenseType: license.license_type,
        expiresAt: new Date(license.expires_at).toLocaleDateString('en'),
        yearCertified: license.year_certified
      },
      loading: false,
      error: '',
      fetchLicense: function(stateInput, licenseNumberInput) {
        let __this = this;
        let state = stateInput.options[stateInput.selectedIndex].dataset.abbreviation;
        let licenseNumber = licenseNumberInput.value;
        let uri = `/asc_licenses/${state}/${licenseNumber}`;

        this.loading = true;
        this.error = '';
        this.selectedLicense = { state, licenseNumber };

        return fetch(uri)
          .then(response => response.json())
          .then(result => {
            __this.filled = true;
            __this.selectedLicense = {
              ...__this.selectedLicense,
              licenseType: result.license_type,
              firstName: result.first_name,
              lastName: result.last_name,
              expiresAt: new Date(result.expiration_date).toLocaleDateString('en'),
              yearCertified: new Date(result.issue_date).getFullYear()
            };
          })
          .catch(error => {
            __this.filled = false;
            __this.error = "Couldn't find a license for this state and number";
          })
          .finally(() => { __this.loading = false });
      }
    };
  };

  if (typeof window !== "undefined") {
    window.vendorLicenseApp = vendorLicenseApp;
  }

  return vendorLicenseApp;
})();
