;(function () {
  const phonesInputApp = function (phones) {
    this.phones = phones;

    return {
      phones,
      addPhone: function () {
        this.phones.push({ id: null, phone_number: null, phone_type: null, position: null });
      },
      removePhone: function (index) {
        if (this.phones[index].id) {
          this.phones[index]._destroy = 1;
        } else {
          this.phones.splice(index, 1);
        }
      },
      addPhoneMask: function (input) {
        Inputmask({ mask: '(999) 999-9999' }).mask(input);
      },
      checkPhonesPresence: function () {
        return this.phones.filter((phone) => phone._destroy !== 1).length > 0;
      }
    };
  }

  if (typeof window !== 'undefined') {
    window.phonesInputApp = phonesInputApp;
  }

return phonesInputApp;

})();
