;(function () {
  const mapViewApp = function (metadataUrl) {
    return {
      init: function () {
        const __this = this

        fetch(metadataUrl)
          .then((response) => response.json())
          .then((json) => {
            if (json.status === 'OK') {
              __this.hasPropertyImage = true
              __this.displayPropertyImage = true
            } else {
              __this.hasPropertyImage = false
              __this.displayMap = true
            }
          })
      },
      toggleTo: function (target) {
        this.disableAll()

        switch (target) {
          case 'map':
            this.displayMap = true
            break
          case 'streetView':
            this.displayStreetView = true
            break
          default:
            this.displayPropertyImage = true
        }
      },
      disableAll: function () {
        this.displayMap = false
        this.displayStreetView = false
        this.displayPropertyImage = false
      },
      hasPropertyImage: false,
      displayPropertyImage: false,
      displayStreetView: false,
      displayMap: false,
    }
  }

  if (typeof window !== 'undefined') {
    window.mapViewApp = mapViewApp
  }

  return mapViewApp
})()
