(function () {
  const tabsApp = function (defaultSelected) {
    return {
      selected: defaultSelected,
      select: function (tab) { this.selected = tab },
    };
  };

  if (typeof window !== "undefined") {
    window.tabsApp = tabsApp;
  }

  return tabsApp;
})();
