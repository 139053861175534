import popoverFactory from './popoverFactory'

export default function Tooltip(portalElement, direction) {
  return popoverFactory(
    portalElement,
    direction,
    'tooltip',
    { arrow: { classList: ['bg-gray-900'] } }
  )
}
