import MD5 from 'crypto-js/md5'

(function () {
  function eachTableHeader (table, callback) {
    table
      .querySelectorAll('th')
      .forEach((el, index) => {
        if (!el.dataset.rtcResizable) return

        callback(el, index)
      })
  }

  function calculateTableKey (table) {
    let name = table.dataset.rtcResizableTable

    eachTableHeader(table, function (el, index) {
      name += ':' + index + '-' + el.dataset.rtcResizable
    })

    return MD5(name).toString()
  }

  function calculateTableColumns (table) {
    const columns = {}

    eachTableHeader(table, function (el, index) {
      columns[el.dataset.rtcResizable] = { index, column: el.dataset.rtcResizable, display: true }
    })

    return columns
  }

  function initTable(table, columns) {
    columns = Object.values(columns)

    columns.forEach(function ({ index, display }) {
      if (display) return

      table
        .querySelectorAll('td:nth-child(' + (index + 1) + '), th:nth-child(' + (index + 1) + ')')
        .forEach((el) => {
          el.style.display = 'none'
        })
    })
  }

  const resizableTableSettings = function (table) {
    const tableKey = calculateTableKey(table)
    const columns = window.localStorageObjectStore.get(tableKey) || calculateTableColumns(table)
    initTable(table, columns)

    return {
      columns: Object.values(columns),
      humanize: function (str) {
        return str
          .replace(/^[\s_]+|[\s_]+$/g, '')
          .replace(/[_-\s]+/g, ' ')
          .replace(/^[a-z]/, (m) => m.toUpperCase())
      },
      open: false,
      isDisplayed: function (column) {
        return columns[column].display
      },
      toggle: function (column) {
        columns[column].display = !columns[column].display

        window.localStorageObjectStore.set(tableKey, columns)

        table
          .querySelectorAll('td:nth-child(' + (columns[column].index + 1) + '), th:nth-child(' + (columns[column].index + 1) + ')')
          .forEach((el) => {
            el.style.display = columns[column].display ? '' : 'none'
          })
      }
    }
  }

  if (typeof window !== "undefined") {
    window.resizableTableSettings = resizableTableSettings;
  }

  return resizableTableSettings;
})();
