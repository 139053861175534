import _ from 'lodash'

const humanizeString = (str) => {
  return str
    .replace(/^[\s_]+|[\s_]+$/g, '')
    .replace(/[_\s]+/g, ' ')
    .replace(/^[a-z]/, function (m) {
      return m.toUpperCase()
    })
}

const capitalizeString = (str) => {
  if (!str) return str

  let newStr = str.charAt(0).toUpperCase()

  for (let ii = 1; ii < str.length; ii += 1) {
    newStr += str.charAt(ii).toLowerCase()
  }

  return newStr
}

const hashFilter = (obj, predicate) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_key, value]) => predicate(value))
  )
}

const camelizeKeys = obj => _.transform(obj, (acc, value, key, target) => {
  const camelKey = _.isArray(target) ? key : _.camelCase(key);

  acc[camelKey] = _.isObject(value) ? camelizeKeys(value) : value;
});

export { humanizeString, capitalizeString, hashFilter, camelizeKeys }
