;(function () {
  const vendorAutoAssignConfigApp = function (vendorId, currentProductIds) {
    const productIds = [...currentProductIds]

    return {
      productIds,
      handleCheck: function (el) {
        const productId = el.value
        let __this = this

        if (el.checked) {
          __this.productIds = [...__this.productIds, productId]
        } else {
          __this.productIds = __this.productIds.filter((id) => id !== productId)
        }
      },
      handleSubmit: function () {
        const body = {
          auto_assign_config: { auto_accept_product_ids: this.productIds },
        }
        const path = `/ordering/vendors/${vendorId}/auto_assign_config`

        new FetchRequest('patch', path, {
          body,
          responseKind: 'turbo-stream',
        }).perform()
      },
    }
  }

  if (typeof window !== 'undefined') {
    window.vendorAutoAssignConfigApp = vendorAutoAssignConfigApp
  }

  return vendorAutoAssignConfigApp
})()
