import { humanizeString, capitalizeString } from './utils';
import _ from 'lodash';

(function () {
  const assignVendors = () => {
    return {
      step: 1,
      allSelected: false,
      selectedVendors: [],
      allVendorsCount: 0,
      yourPanelCount: 0,
      preferredVendorCredential: false,
      vendorsPagination: {
        total: 0,
        current_page: 1,
        next_page: null,
        prev_page: null,
        total_pages: 0
      },
      vendorScope: 'your_panel',
      vendors: [],
      products: [],
      searchLoading: false,
      filterFieldsOpen: false,
      filterFieldsActive: false,
      filterText: 'Filter',
      loading: true,
      currencyFormatter: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
      assignmentMode: 'round-robin',
      capitalizeString: capitalizeString,
      toggleAllSelected: function () {
        const vendorIds = this.vendors.map(vendor => vendor.id)

        if (this.allSelected) {
          this.selectedVendors = [
            ...this.selectedVendors.filter(vendor => !vendorIds.includes(vendor.id))
          ]

          this.allSelected = false
        } else {
          this.selectedVendors = [...this.selectedVendors, ...this.vendors]
          this.allSelected = true
        }
      },
      isSelectedVendor: function(vendor) {
        return this.selectedVendors.some(obj => obj.id === vendor.id);
      },
      selectResult: function (vendor) {
        let index = this.selectedVendors.findIndex(obj => obj.id === vendor.id);

        if (index === -1) {
          this.selectedVendors.push(vendor);
        } else {
          this.selectedVendors.splice(index, 1);
        }
      },
      getUri: function (fields = [], order_id = '', vendorScope = 'all_vendors', page = 1) {
        const [searchField, ...filterFields] = fields;
        let filterParams = {};

        if (searchField) {
          filterParams = {
            name_or_email_cont: searchField
          };
        }

        const q = this.toParam(filterParams);

        let uri =
          `/ordering/vendors.json?order_id=${order_id}&vendor_scope=${vendorScope}&page=${page}`;

        if (q) uri += `&${q}`;

        if (filterFields.filter((n) => Boolean(n)).length) {
          const strategies = filterFields.filter((n) => Boolean(n)).join(',');

          uri += `&strategy=${strategies}`;
        }

        return uri;
      },
      fetchResults: function (fields, order_id, vendorScope, page = 1) {
        let __this = this;

        this.showResults = true;
        this.loading = true;

        return fetch(this.getUri(fields, order_id, vendorScope, page))
          .then((response) => response.json())
          .then((result) => {
            __this.vendors = result.vendors;
            __this.allVendorsCount = result.all_vendors_count;
            __this.yourPanelCount = result.your_panel_count;
            __this.preferredVendorCredential = result.preferred_vendor_credential;
            __this.vendorsPagination = result.pagination;
            __this.loading = false;
            __this.searchLoading = false;
            __this.filterFieldsOpen = false;
            __this.updateFilterText(__this.filterFields);

            const vendorIds = __this.vendors.map(vendor => vendor.id)
            const selectedVendorIds = __this.selectedVendors.map(vendor => vendor.id)

            __this.allSelected = vendorIds.length > 0 &&
              vendorIds.every(vendor => selectedVendorIds.includes(vendor))
          });
      },
      fetchVendorNotes: function (vendorId, serviceType) {
        let __this = this;
        let uri = `/ordering/vendors/${vendorId}/notes.turbo_stream`;

        if (serviceType) { uri = uri + `?service_type=${serviceType}` };

        return fetch(uri)
      },
      upNdown: function(index, direction) {
        let rows = document.getElementById("sortSelectedVendors").rows

        if (direction === "up") {
          if (index >= 1) {
            [this.selectedVendors[index], this.selectedVendors[index - 1]] =
              [this.selectedVendors[index - 1], this.selectedVendors[index]]
          }
        }

        if (direction === "down") {
          if (index < rows.length -1) {
            [this.selectedVendors[index + 1], this.selectedVendors[index]] =
              [this.selectedVendors[index], this.selectedVendors[index + 1]]
          }
        }
      },
      clear: function (fields) {
        fields.forEach((field) => {
          field.checked = false;
        });

        this.toggleFilterFieldsActive(fields);
      },
      getValues: function (fields) {
        return [...fields].map((field) => {
          switch (field.type) {
            case 'checkbox':
              return field.checked ? field.value : '';

            default:
              return field.value ? field.value : '';
          }
        });
      },
      updateFilterText: function (fields) {
        const checked = fields.filter((field) => field.checked);

        if (!checked.length) return (this.filterText = 'Filter');

        const text = checked
          .map((filter) => humanizeString(filter.name))
          .join(', ');

        if (text.length > 25) {
          this.filterText = text.substring(0, 25) + '...';
        } else {
          this.filterText = text;
        }
      },
      toggleFilterFieldsActive: function (fields) {
        const checked = [...fields]
          .map((field) => field.checked)
          .some((c) => c === true);

        if (checked) {
          this.filterFieldsActive = true;
        } else {
          this.filterFieldsActive = false;
        }
      },
      toParam: function (hash) {
        return Object.keys(hash)
          .map((key) => `q[${key}]=${hash[key]}`)
          .join('&');
      },
      preferredCredentialCheck: function (vendor) {
        return !vendor.ineligible && !vendor.accredited_vendor && this.preferredVendorCredential;
      },
      insuranceCoverageCheck: function (vendor) {
        return parseFloat(vendor.insurance_coverage) < parseFloat(vendor.minimal_insurance_coverage)
      },
      stepTwoNext: function (products) {
        return (!this.assignmentMode) ||
               (this.assignmentMode === 'round-robin' &&
               products.reduce((fees, product) => {
                 fees.push(parseFloat(product.fee)); return fees
               }, []).includes(0.00))
      },
      assignSuggestionFeeForProducts: function () {
        this.products.forEach((product) => {
          product.fee = this.suggestionFeeFor(product.id)
        })
      },
      suggestionFeeFor: function (productId) {
        const selectedVendorProductFees = this.selectedVendors.map((v) => v.product_fee_table)
        const fees = _.compact([...selectedVendorProductFees].flat());

        return fees.find((fee) => fee.product_id === productId)?.fee || 0;
      }
    };
  };

  if (typeof window !== "undefined") {
    window.assignVendorsApp = assignVendors;
  }

  return assignVendorsApp;
})();
