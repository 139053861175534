;(function () {
  Trix.config.textAttributes.underline = { tagName: 'u', inheritable: true }

  document.addEventListener('trix-initialize', function (e) {
    const element = e.target
    const editor = element.editor
    const toolbarElement = element.toolbarElement
    const groupElement = toolbarElement.querySelector(
      '.trix-button-group.trix-button-group--text-tools'
    )

    groupElement.insertAdjacentHTML(
      'beforeend',
      `<button type="button"
               class="trix-button trix-button--icon trix-button--icon-underline"
               data-trix-attribute="underline">
        <sub>Underline</sub>
      </button>`
    )

    let selectedAttributes = new Set()

    function updateSelectedAttributes() {
      selectedAttributes = new Set()

      const addAttribute = (attribute) => selectedAttributes.add(attribute)

      let selectedRange = editor.getSelectedRange()

      if (selectedRange[0] === selectedRange[1]) selectedRange[1]++

      editor
        .getDocument()
        .getDocumentAtRange(selectedRange)
        .getPieces()
        .forEach(function (piece) {
          Object.keys(piece.getAttributes()).forEach(addAttribute)
        })
    }

    updateSelectedAttributes()

    element.addEventListener('trix-selection-change', updateSelectedAttributes)

    if (element.dataset.content) editor.insertHTML(element.dataset.content)
  })
})()
