import _ from 'lodash';

(function () {
  const batchActionsApp = function (batchActionsName, availableIds, options = {}) {
    return {
      availableIds: availableIds,
      selectedIds: [],
      preffix: options.preffix || 'batch-',
      selectAllCheckbox: null,
      initialize: function(input, $dispatch) {
        this.selectAllCheckbox = input;
        this.$dispatch = $dispatch;
      },
      addPreffix: function(id) {
        return `${this.preffix}${id}`;
      },
      removePreffix: function(id) {
        return id.replace(this.preffix, '');
      },
      toggleSelectAll: function(checked) {
        this.selectAllCheckbox.checked = checked;
      },
      onToggleSingleRow: function(event) {
        const elementId = this.removePreffix(event.target.id);
        const index = this.selectedIds.indexOf(elementId);

        if (index >= 0) {
          this.selectedIds = this.selectedIds.filter(selectedId => selectedId != elementId)
        } else {
          this.selectedIds = [...this.selectedIds, elementId];
        }

        this.toggleSelectAll(_.isEqual(this.selectedIds.sort(), this.availableIds.sort()));

        this.publishChanged();
      },
      onToggleAllRows: function() {
        const checked = this.selectAllCheckbox.checked;

        this.selectedIds = (checked) ? this.availableIds.slice(0) : [];
        this.availableIds.forEach((id) => {
          document.getElementById(this.addPreffix(id)).checked = checked;
        });

        this.publishChanged();
      },
      publishChanged: function () {
        this.$dispatch('batch-actions--changed', { name: batchActionsName, selectedIds: this.selectedIds });
      }
    };
  };

  if (typeof window !== "undefined") {
    window.batchActionsApp = batchActionsApp;
  }

  const batchActionsButtonApp = function (batchActionsName) {
    return {
      selectedIds: [],
      urlWithIds: function(url, param) {
        const params = this.selectedIds
                          .map(id => `${param}[]=${id}`)
                          .join('&');

        return `${url}?${params}`;
      },
      consume: function (eventDetail) {
        if (eventDetail.name !== batchActionsName) return;

        this.selectedIds = eventDetail.selectedIds;
      }
    };
  }

  if (typeof window !== "undefined") {
    window.batchActionsButtonApp = batchActionsButtonApp;
  }

  return batchActionsApp;
})();
