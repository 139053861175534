import Sortable from 'sortablejs'

;(function () {
  const draggableApp = function (
    { filter, handle } = { filter: null, handle: null }
  ) {
    return {
      init: function (element, callback) {
        this.sortable = Sortable.create(element, {
          onEnd: callback,
          animation: 200,
          filter,
          handle,
        })
      },
    }
  }

  if (typeof window !== 'undefined') {
    window.draggableApp = draggableApp
  }

  return draggableApp
})()
