(function () {
  const rangeDatepickerApp = function (options = {}) {
    let fp;

    return {
      datepickerInput: null,
      gteqInput: null,
      lteqInput: null,
      options: { altFormat: 'm/d/Y', altInput: true, ...options },
      clear: function() { fp.clear(); },
      initialize: function(datepickerInput, gteqInput, lteqInput) {
        const __this = this;

        __this.datepickerInput = datepickerInput;
        __this.gteqInput = gteqInput;
        __this.lteqInput = lteqInput;

        fp = flatpickr(datepickerInput, {
          ...__this.options,
          mode: 'range',
          defaultDate: [__this.gteqInput.value, __this.lteqInput.value],
          onChange: function(selectedDates) {
            const dateArr = selectedDates.map(date => this.formatDate(date, "Y-m-d"));

            if (!dateArr.length) return

            __this.gteqInput.value = dateArr[0];
            __this.lteqInput.value = dateArr[1];
          }
        });
      }
    }
  }

  if (typeof window !== "undefined") {
    window.rangeDatepickerApp = rangeDatepickerApp;
  }

  return rangeDatepickerApp;
})();
