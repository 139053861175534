(function () {
  const durationSelectApp = function(currentValueInDuration) {
    return {
      valueInDuration: currentValueInDuration,
      duration: 'hours',
      selectedFormat: null,
      inputValue: 0,
      durationOptions: {
        hours: 1,
        days: 24
      },
      init: function() {
        this._loadSelectedFormat();
        this._loadInputValue();
      },
      validateInputValue: function() {
        this.inputValue =
          !!this.inputValue && Math.abs(this.inputValue) >= 0 ? Math.abs(this.inputValue) : null
      },
      calculateValueInDuration: function() {
        this.valueInDuration = this.inputValue * this.durationOptions[this.selectedFormat]
      },
      _loadSelectedFormat: function() {
        if (this.valueInDuration >= 24 && this.valueInDuration % 24 === 0) {
          this.selectedFormat = 'days'
        } else {
          this.selectedFormat = 'hours'
        }
      },
      _loadInputValue: function() {
        if (this.valueInDuration === 0) {
          this.inputValue = null
        } else {
          this.inputValue = this.valueInDuration / this.durationOptions[this.selectedFormat]
        }
      }
    };
  };

  if (typeof window !== "undefined") {
    window.durationSelectApp = durationSelectApp;
  }

  return durationSelectApp;
})();
