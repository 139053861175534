import qs from 'qs';

(function () {
  const newInvoiceApp = function (invoices, searchPath, selectedInvoices = []) {
    function matchFilterInput(el) {
      return el.name.match(/^closed_invoice\[q\]\[(\w+)\]$/)
    }

    return {
      invoices,
      invoiceIds: invoices.map(i => i.id),
      selectedInvoiceIds: (selectedInvoices.length ? selectedInvoices : invoices).map(i => i.id),
      filtersOpen: false,
      durties: {},
      durty: function () {
        return Object.values(this.durties).includes(true)
      },
      total: function () {
        return this.invoices.reduce((acc, invoice) => {
          if (this.selectedInvoiceIds.includes(invoice.id)) {
            return acc + parseFloat(invoice.balance)
          } else {
            return acc
          }
        }, 0).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
      },
      search: function ($dispatch) {
        const params = { q: {} }

        document.querySelectorAll('input, select').forEach(el => {
          const matched = matchFilterInput(el)
          if (matched && matched[1] && el.value && el.value !== '') {
            params.q[matched[1]] = el.value
          }
        })

        const query = qs.stringify(params)

        document
          .getElementById('closed_invoice_invoices_table')
          .setAttribute('src', `${searchPath}?${query}`)

        $dispatch('dismiss-drawer-filter')
      },
      clearFilters: function ($dispatch) {
        document.querySelectorAll('input, select').forEach(el => {
          if (!matchFilterInput(el)) return

          el.value = ''
          this.durties[el.name] = false
        })

        this.search($dispatch)
      },
      toggleSelection: function (e) {
        const invoiceId = e.target.dataset.id

        if (e.target.checked) {
          this.selectedInvoiceIds = [...this.selectedInvoiceIds, invoiceId]
        } else {
          this.selectedInvoiceIds = this.selectedInvoiceIds.filter(id => id !== invoiceId)
        }

        if (this.selectedInvoiceIds.length === this.invoiceIds.length) {
          document.querySelector('#toggle-all').checked = true
        } else {
          document.querySelector('#toggle-all').checked = false
        }
      },
      toggleAllSelection: function (e) {
        if (e.target.checked) {
          this.selectedInvoiceIds = [...this.invoiceIds]
          document.querySelectorAll('.invoice-checkbox').forEach(el => el.checked = true)
        } else {
          this.selectedInvoiceIds = []
          document.querySelectorAll('.invoice-checkbox').forEach(el => el.checked = false)
        }
      },
      init: function (form, $dispatch) {
        form.querySelectorAll('*[data-filled]').forEach(el => {
          this.durties[el.name] = el.dataset.filled === 'true'
        })

        form.querySelectorAll('input[type=text], select').forEach(el => {
          el.addEventListener('keydown', (e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              this.search($dispatch)
            }
          })

          if (!matchFilterInput(el) || el.dataset.skipDirty) return

          el.addEventListener('change', () => {
            if (el.value === '') {
              this.durties[el.name] = false
            } else {
              this.durties[el.name] = true
            }
          })
        })

        setTimeout(() => {
          document.querySelectorAll('.invoice-checkbox').forEach((el) => {
            if (this.selectedInvoiceIds.includes(el.dataset.id)) {
              el.checked = true
            }
          })
        }, 100)
      }
    }
  };

  if (typeof window !== "undefined") {
    window.newInvoiceApp = newInvoiceApp;
  }

  return newInvoiceApp;
})();
