import * as ResizableTable from '@validide/resizable-table-columns'
import CollateralStore from './table/collateralStore'
import createSwapColumn from './table/swapColumns'

export { default as HideableColumns } from './table/hideableColumns'
export { default as CSVApp } from './table/csvApp'

export default function initializeTable(table) {
  const resizableTable = new ResizableTable.ResizableTableColumns(table, {
    maxInitialWidthHint: 200,
    maxWidth: 500,
    store: new CollateralStore(),
  })

  createSwapColumn(table)

  table.addEventListener('hideable-columns:change', function (event) {
    const table = event.target
    const wrapper = table.closest('.rtc-wrapper')
    const columnsWidth = Array.from(table.querySelectorAll('th'))
      .filter((el) => el.style.display !== 'none')
      .reduce((acc, current) => acc + parseInt(current.style.width), 0)
    const wrapperWidth = wrapper.offsetWidth
    const tableWidth = columnsWidth < wrapperWidth ? wrapperWidth : columnsWidth

    resizableTable.table.style.width = `${tableWidth}px`
    resizableTable.syncHandleWidths()
  })

  return { table, resizableTable }
}
