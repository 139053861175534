(function () {
  const bidsApp = function () {
    return {
      bids: [{fee: 0, business_due_date: '', comments: ''}],
      addBid: function () {
        this.bids.push({fee: 0, business_due_date: '', comments: ''});
        setTimeout(() => { this.addPriceMask(); }, 10);
      },
      removeBid: function (index) {
        this.bids.splice(index, 1);
      },
      checkBids: function() {
        return (this.bids.length === 0 ||
                this.bids.some((bid) => bid.fee == 0 || bid.business_due_date == ""))
      },
      addPriceMask: function () {
        let priceInputs = document.getElementsByClassName('price-input');
        Inputmask({ removeMaskOnSubmit: true, alias: 'currency', groupSeparator: ',' }).mask(priceInputs);
      }
    };
  };

  if (typeof window !== "undefined") {
    window.bidsApp = bidsApp;
  }

  return bidsApp;
})();
