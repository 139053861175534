(function () {
  const orderWizardPropertyApp = function (
    serviceType,
    loanType,
    transactionType,
    { orderId }
  ) {
    return {
      serviceType,
      loanType,
      transactionType,
      orderId,
      loadSelectOptions: function (path, ref) {
        return fetch(path)
          .then((response) => response.text())
          .then((html) => (ref.innerHTML = html));
      },
      addCurrencyMask: function (input) {
        Inputmask({
          alias: 'currency',
          groupSeparator: ',',
          removeMaskOnSubmit: true,
        }).mask(input);
      },
      loanOptionsPath: function (serviceType = null) {
        return this.optionsPath('loan_types', serviceType);
      },
      propertyOptionsPath: function (serviceType = null) {
        return this.optionsPath('property_types', serviceType);
      },
      transactionOptionsPath: function (serviceType = null) {
        return this.optionsPath('transaction_types', serviceType);
      },
      optionsPath: function (resource, serviceType = null) {
        const path = `/ordering/orders/${this.orderId}/${resource}`;

        return serviceType ? `${path}?service_type=${serviceType}` : path;
      },
    };
  };

  if (typeof window !== 'undefined') {
    window.orderWizardPropertyApp = orderWizardPropertyApp;
  }
})();
