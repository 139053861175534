export default function csvApp(path, queryParams) {
  const filterHiddenColumns = (columns) =>
    columns.filter((c) => !c.display).map((c) => c.column)

  const queryString = (columns) => {
    const allParams = [
      ...queryParams,
      ['hidden_columns', filterHiddenColumns(columns)],
    ]

    return new URLSearchParams(allParams).toString()
  }

  const buildLinkPath = (columns) => `${path}?${queryString(columns)}`

  return {
    loading: false,
    columns: [],
    linkPath: '#',
    updateColumns: function (columns) {
      this.columns = columns
      this.linkPath = buildLinkPath(this.columns)
    },
  }
}
