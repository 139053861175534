(function () {
  const vendorPanelFilter = (text, active) => {
    return {
      text: text,
      open: false,
      active: active,
      openOrActive: function () {
        return this.open || this.active;
      },
      toggleOpen: function () {
        this.open = !this.open;
      },
      clear: function (fields) {
        this.open = false;
        this.active = false;
        this.text = 'Filter';

        fields.forEach((field) => {
          return (field.value = '');
        });
      },
      fillSelect: function (field, { value, url, blank }) {
        return fetch(url + value)
          .then((response) => response.text())
          .then((html) => {
            field.innerHTML =
              `<option value="">${blank}</option>` +
              html.replace('<option value=""></option>', '');
          });
      },
    };
  };

  if (typeof window !== 'undefined') {
    window.vendorPanelFilter = vendorPanelFilter;
  }

  return vendorPanelFilter;
})();
