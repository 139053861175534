(function () {
  const changeTermsTagifyPlugin = function (name) {
    return function (tagify, $dispatch) {
      tagify.on('change', function (e) {
        $dispatch('order-terms-changed', {
          productIds: e.detail.tagify.value.map((tag) => tag.id),
          name,
        });
      });
    };
  };

  const changeTermsApp = function () {
    return {
      loading: {
        paying: false,
        payouting: false,
      },
      main: [],
      addon: [],
      update: function (e, turboFrame) {
        const __this = this;

        this.loading[turboFrame.dataset.name] = true;
        this[e.detail.name] = e.detail.productIds;

        const src = turboFrame.dataset.src;
        const query = this.main
          .map((id) => `product_ids[]=${id}`)
          .join('&');
        const fullSrc = `${src}?${query}`;

        turboFrame.setAttribute('src', fullSrc);
      },
    };
  };

  if (typeof window !== 'undefined') {
    window.changeTermsTagifyPlugin = changeTermsTagifyPlugin;
    window.changeTermsApp = changeTermsApp;
  }

  return changeTermsTagifyPlugin;
})();
