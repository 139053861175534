import { calculateTableKey, eachTableHeader } from './helpers'

const MIN_COLUMN_OFFSET_WIDTH = 200
const INITIAL_COLUMN_WIDTH = '200px'
const ACTION_COLUMN_WITDTH = '50px'

function sortColumns(a, b) {
  const ca = a.column
  const cb = b.column

  if (ca === cb) return 0

  return ca < cb ? -1 : 1
}

function calculateTableColumns(table) {
  const columns = {}

  eachTableHeader(table, function (el, index) {
    columns[el.dataset.name] = {
      index,
      column: el.dataset.name,
      hideable: el.dataset.hideable,
      display: el.dataset.hideable ? !el.dataset.defaultHidden : true,
    }
  })

  return columns
}

function initTable(table, columns) {
  columns = Object.values(columns)

  setTimeout(() => {
    table.dispatchEvent(
      new CustomEvent('hideable-columns:change', {
        detail: { columns },
        bubbles: true,
      })
    )
  }, 300)

  const setInitialWidthIfNotSet = (el) => {
    if (!el.style.width || el.style.width === '0px') {
      el.style.width = INITIAL_COLUMN_WIDTH
    }
  }

  columns.forEach(function ({ index, display, column }) {
    const selector =
      'td:nth-child(' + (index + 1) + '), th:nth-child(' + (index + 1) + ')'

    if (display) {
      table.querySelectorAll(selector).forEach((el) => {
        if (column === 'actions') {
          el.style.width = ACTION_COLUMN_WITDTH
        } else {
          setInitialWidthIfNotSet(el)
        }
      })
    } else {
      table.querySelectorAll(selector).forEach((el) => {
        el.style.display = 'none'
        setInitialWidthIfNotSet(el)
      })
    }
  })
}

export default function hideableColumns(
  table,
  { sortedColumns, ignoreLocalStorage }
) {
  if (!table) return {}

  const tableKey = calculateTableKey(table)
  let columns = ignoreLocalStorage
    ? calculateTableColumns(table)
    : window.localStorageObjectStore.get(tableKey)

  if (!columns) {
    columns = calculateTableColumns(table)

    if (Object.keys(columns).length > 0)
      window.localStorageObjectStore.set(tableKey, columns)
  }

  let hideableCols = Object.keys(columns).reduce((acc, key) => {
    if (columns[key].hasOwnProperty('hideable')) {
      acc[key] = columns[key]
    }
    return acc
  }, {})

  initTable(table, columns)

  return {
    hideableColumns: sortedColumns
      ? Object.values(hideableCols).sort(sortColumns)
      : Object.values(hideableCols),
    isColumnVisible: function (column) {
      return columns[column].display
    },
    toggleColumnVisibility: function (column) {
      columns[column].display = !columns[column].display

      window.localStorageObjectStore.set(tableKey, columns)

      table
        .querySelectorAll(
          'td:nth-child(' +
            (columns[column].index + 1) +
            '), th:nth-child(' +
            (columns[column].index + 1) +
            ')'
        )
        .forEach((el) => {
          el.style.display = columns[column].display ? '' : 'none'
        })

      table.dispatchEvent(
        new CustomEvent('hideable-columns:change', {
          detail: { columns: Object.values(columns) },
          bubbles: true,
        })
      )
    },
    humanize: function (str) {
      return str
        .replace(/^[\s_]+|[\s_]+$/g, '')
        .replace(/[_-\s]+/g, ' ')
        .replace(/^[a-z]/, (m) => m.toUpperCase())
    },
  }
}
