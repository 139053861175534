(function () {
  const coverageAreaStateApp = function (
    countiesConfig,
    countySelectId,
    countiesToTagify = null
  ) {
    return {
      countiesConfig,
      countiesToTagify,
      countyTags: null,
      stateChanged: function (stateId) {
        fetch(`/counties?state_id=${stateId}&name_suffix=false`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
          .then((response) => response.json())
          .then(this.assignCountyTags.bind(this));
      },
      assignCountyTags: function (
        counties,
        whitelist = null,
        { selectedTags } = { selectedTags: false }
      ) {
        this.destroyCountyTags();

        const countySelect = document.getElementById(countySelectId);
        const tagInstance = new window.Tagify(countySelect, {
          whitelist: whitelist || counties,
          enforceWhitelist: true,
          dropdown: {
            classname: 'tags-look',
            enabled: 0,
            maxItems: 20,
            position: 'all',
            closeOnSelect: false,
          },
        });

        this.countyTags = tagInstance;

        if (selectedTags) this.countyTags.addTags(counties);
      },
      toggleCountiesConfig: function (value) {
        this.countiesConfig = value;
        this.removeCountyTags();
      },
      destroyCountyTags: function () {
        this.removeCountyTags();
        this.countyTags?.destroy();
      },
      removeCountyTags: function () {
        this.countyTags?.removeAllTags();
      },
    };
  };

  if (typeof window !== 'undefined') {
    window.coverageAreaStateApp = coverageAreaStateApp;
  }

  return coverageAreaStateApp;
})();
