import consumer from '../channels/consumer'
import { v4 as uuidV4 } from 'uuid'

(function () {
  const orderInteractionsApp = function (orderId, subscriberId) {
    return {
      orderId: orderId,
      subscriberId: subscriberId,
      subscription: null,
      connId: uuidV4(),
      subscribe: function (e) {
        this.subscription = consumer.subscriptions.create({
          channel: 'OrderInteractions::TracksChannel',
          order_id: this.orderId,
          subscriber_id: this.subscriberId,
          conn_id: this.connId
        })
      },
      unsubscribe: function (e) {
        if (this.subscription) { this.subscription.unsubscribe() }
      }
    }
  }

  if (typeof window !== 'undefined') {
    window.orderInteractionsApp = orderInteractionsApp;
  }

  return orderInteractionsApp;
})();
