import { calculateTableKey } from '../../../gems/collateral_design/app/javascripts/table/helpers'
import { hashFilter, humanizeString } from './utils'
;(function () {
  function orderTableColumnsApp(tableId, summarizableColumns) {
    const table = document.getElementById(tableId)
    const tableKey = calculateTableKey(table)
    const tableColumns = window.localStorageObjectStore.get(tableKey) || []
    const columns = Object.values(
      hashFilter(tableColumns, (c) => c.display)
    ).map((c) => c.column)

    return {
      columns: JSON.stringify(columns),
      summarizableColumns: columns.filter((c) =>
        summarizableColumns.includes(c)
      ),
      humanizeString: humanizeString,
    }
  }

  if (typeof window !== 'undefined')
    window.orderTableColumnsApp = orderTableColumnsApp

  return orderTableColumnsApp
})()
