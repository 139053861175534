(function () {
  const anchorUrlApp = function () {
    return {
      anchor: window.location.hash?.slice(1),
      handleUrlAnchor: function () {
        const anchor = this.anchor;

        if (!anchor) return;

        const component = anchor.split('!')[0];

        if (component === 'tab') this.handleTab(anchor);
      },
      handleTab: function (anchor) {
        const [group, tab] = this.resolveTab(anchor);

        document
          .querySelector(`[data-tabs-name="${group}"]`)
          ?.querySelector(`[data-tab-name="${tab}"]`)
          ?.click();
      },
      resolveTab: function (anchor, { fallback } = {}) {
        if (!anchor) return [null, fallback];

        const groupAndTab = anchor.split('!')[1];

        if (groupAndTab) {
          return groupAndTab.split('-');
        } else {
          return [null, fallback];
        }
      },
    };
  };

  if (typeof window !== 'undefined') {
    window.anchorUrlApp = anchorUrlApp;
  }

  return anchorUrlApp;
})();
