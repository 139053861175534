export default class CollateralStore {
  constructor() {
    this.dataStore = window.localStorageObjectStore
    this.userId = document.body.getAttribute('data-user-id')
  }

  get(id) {
    return this.dataStore.get(`${this.userId}/${id}`)
  }

  set(id, data) {
    this.dataStore.set(`${this.userId}/${id}`, data)
  }
}
