(function () {
  const switchButton = function (initialValue, options = {}) {
    return {
      currentValue: initialValue,
      dynamicLabel: '',
      config: {
        url: options.url,
        on: {
          value: options.on_value || 'true',
          class: options.on_class || 'bg-brand-500'
        },
        off: {
          value: options.off_value || 'false',
          class: options.off_class || 'bg-gray-500'
        }
      },
      disabled: options.disabled || false,
      toggle: function (dispatch) {
        if (this.disabled) return;

        this.currentValue = this.isEnabled() ? this.config.off.value : this.config.on.value;

        this.setDynamicLabel();

        if (this.config.url) {
          body = JSON.stringify({ value: this.currentValue });
          request = new FetchRequest('patch', this.config.url, {
            body,
            responseKind: 'turbo-stream'
          })

          request.perform();
        }

        dispatch('switch-button--toggled', { enabled: this.isEnabled(), name: options.name })
      },
      isEnabled: function () {
        return this.currentValue === this.config.on.value
      },
      isDisabled: function () {
        return !this.isEnabled();
      },
      bgClass: function() {
        return {
          [this.config.off.class]: this.isDisabled(),
          [this.config.on.class]: this.isEnabled()
        };
      },
      positionClass: function() {
        return {
          'switch-button__switcher--disabled': this.isDisabled(),
          'switch-button__switcher--enabled': this.isEnabled()
        }
      },
      setDynamicLabel: function () {
        if (this.currentValue === this.config.on.value) {
          this.dynamicLabel = options.on_label;
        } else {
          this.dynamicLabel = options.off_label;
        }
      },
    };
  };

  if (typeof window !== "undefined") {
    window.switchButton = switchButton;
  }

  return switchButton;
})();
