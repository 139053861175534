import popoverFactory from './popoverFactory'

export default function Tooltip(portalElement, direction, alignment, classList) {
  return popoverFactory(
    portalElement,
    direction,
    'popover',
    { alignment, arrow: { classList } }
  )
}
