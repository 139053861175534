import MD5 from 'crypto-js/md5'

const calculateTableKey = (table) => {
  const hideableHeaders = []
  const userId = document.body.getAttribute('data-user-id')

  eachTableHeader(table, function (el, index) {
    hideableHeaders.push(el.dataset.name)
  })

  hideableHeaders.sort()

  return MD5(`${userId}:${table.id}:${hideableHeaders.join(':')}`).toString()
}

const eachTableHeader = (table, callback) => {
  table.querySelectorAll('th').forEach((el, index) => {
    callback(el, index)
  })
}

export { calculateTableKey, eachTableHeader }
