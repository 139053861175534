(function () {
  const userMenuApp = function () {
    return {
      searchBarOpened: false,
      searchLoading: false,
      openSearchBar: function (refs) {
        this.searchBarOpened = true;

        setTimeout(function () {
          refs.searchField.focus();
        }, 100)
      },
      dismissSearchBar: function() {
        this.searchBarOpened = false;
      },
      searchStarted: function() {
        this.searchLoading = true;
      },
      searchEnded: function() {
        this.searchLoading = false;
      }
    };
  };

  if (typeof window !== "undefined") {
    window.userMenuApp = userMenuApp;
  }

  return userMenuApp;
})();
