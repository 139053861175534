(function () {
  function notifyNow(type, message) {
    const event = new CustomEvent(
      'notification-added',
      { bubbles: true, detail: { type, message } }
    );

    document.body.dispatchEvent(event);
  }

  const flashMessageApp = function (notifications = []) {
    const SHOW_TIME = 5000;

    const buildNotification = notification => ({
      type: notification[0],
      message: notification[1],
      show: true,
    });

    return {
      notifications: [],
      addNotification: function (type, message) {
        this.notifications.push(buildNotification([type, message]));
        const __this = this;
        const toHide = this.notifications.length - 1;
        setTimeout(() => __this.hideNotification(toHide), SHOW_TIME);
      },
      hideNotification: function (index) {
        this.notifications[index].show = false;
      },
      initNotifications: function () {
        const __this = this;

        notifications.forEach(notification => {
          __this.addNotification(notification[0], notification[1]);
        });

        document.body.addEventListener('ajax:success', event => {
          const { detail: [data] } = event;

          if (data.status) __this.addNotification(data.status, data.message);
        });
      },
    };
  };

  if (typeof window !== "undefined") {
    window.flashMessageApp = flashMessageApp;
    window.notifyNow = notifyNow;
  }

  return flashMessageApp;
})();
