function setDirection(direction, portalElement, target) {
  let { top, left } = target.getBoundingClientRect()
  top += window.scrollY
  left += window.scrollX

  if (direction === 'top') {
    portalElement.style.top = `${top - portalElement.offsetHeight}px`
  }

  if (direction === 'bottom') {
    portalElement.style.top = `${top + target.offsetHeight}px`
  }

  if (direction === 'left') {
    portalElement.style.left = `${left - 6 - portalElement.offsetWidth}px`
  }

  if (direction === 'right') {
    portalElement.style.left = `${left + 6 + target.offsetWidth}px`
  }
}

function setAlignment(direction, alignment, portalElement, target) {
  let { top, left, width, height } = target.getBoundingClientRect()

  top += window.scrollY
  left += window.scrollX

  if (direction === 'right' || direction === 'left') {
    if (alignment === 'center') {
      portalElement.style.top = `${top + height / 2 - portalElement.offsetHeight / 2}px`
    }

    if (alignment === 'top') {
      portalElement.style.top = `${top}px`
    }

    if (alignment === 'bottom') {
      portalElement.style.top = `${top + height - portalElement.offsetHeight}px`
    }
  }

  if (direction === 'bottom' || direction === 'top') {
    if (alignment === 'center') {
      portalElement.style.left = `${left + width / 2 - portalElement.offsetWidth / 2}px`
    }

    if (alignment === 'left') {
      portalElement.style.left = `${left}px`
    }

    if (alignment === 'right') {
      portalElement.style.left = `${left + width - portalElement.offsetWidth}px`
    }
  }
}

function createArrow(direction, options = {}) {
  const arrow = document.createElement('div')

  arrow.style.position = 'absolute'
  arrow.style.height = '8px'
  arrow.style.width = '8px'
  arrow.style.transform = 'rotate(45deg)'

  if (options.classList) {
    arrow.classList.add(...options.classList)
  }

  if (options.style) {
    options.style.forEach((style, value) => {
      arrow.style[style] = value
    })
  }

  if (direction === 'top') {
    arrow.style.top = 'calc(100% - 4px)'
    arrow.style.left = 'calc(50% - 4px)'
  }

  if (direction === 'bottom') {
    arrow.style.top = '-4px'
    arrow.style.left = 'calc(50% - 4px)'
  }

  if (direction === 'left') {
    arrow.style.top = 'calc(50% - 4px)'
    arrow.style.left = 'calc(100% - 4px)'
  }

  if (direction === 'right') {
    arrow.style.top = 'calc(50% - 4px)'
    arrow.style.left = '-4px'
  }

  return arrow
}

export default function popoverFactory(
  portalElement,
  direction,
  elementType = 'tooltip',
  { alignment = 'center', arrow = {} }
) {
  function show(event) {
    const popoverContent = event.currentTarget.querySelectorAll(
      `*[data-${elementType}]`
    )[0]

    if (!popoverContent) return

    portalElement.innerHTML = popoverContent.innerHTML
    portalElement.style.display = 'block'

    setDirection(direction, portalElement, event.currentTarget)
    setAlignment(direction, alignment, portalElement, event.currentTarget)

    if (Object.entries(arrow).length > 0) {
      portalElement.appendChild(createArrow(direction, arrow))
    }
  }

  function hide() {
    portalElement.style.display = 'none'
  }

  function toggleDisplay(event) {
    const current = portalElement.style.display

    if (current === 'none') {
      show(event)
    } else {
      hide()
    }
  }

  return { show, hide, toggleDisplay }
}
