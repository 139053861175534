(function () {
  const modalApp = function (modalDialogId, confirmModalId) {
    return {
      opened: false,
      confirmationUri: null,
      showClose: true,
      confirmationMethod: null,
      openConfirmationModal: function (event) {
        const modalDialog = document.getElementById(modalDialogId);

        this.confirmationUri = event.detail.uri;
        this.confirmationMethod = event.detail.method || 'delete';
        modalDialog.innerHTML = document.getElementById(confirmModalId).innerHTML;
        this.opened = true;
      },
      openModal: function (event) {
        const modalDialog = document.getElementById(modalDialogId);

        if (event.detail.template) {
          modalDialog.innerHTML = document.getElementById(event.detail.template).innerHTML;
        }

        if (typeof event.detail.callback === 'function') {
          event.detail.callback();
        } else if (typeof event.detail.callback === 'string') {
          eval(event.detail.callback);
        }

        this.opened = true;
        this.showClose = !event.detail.hideClose;
      },
      dismiss: function () {
        this.opened = false;
        document.getElementById('modal_app')?.removeAttribute('src');
      }
    };
  };

  if (typeof window !== "undefined") {
    window.modalApp = modalApp;
  }

  return modalApp;
})();
