import _ from 'lodash';

(function () {
  const advancedSearcheableApp = function () {
    return {
      advancedPanel: null,
      advanced: false,
      usingAdvancedFiltersCount: 0,
      initialize: function(advancedPanel) {
        this.advancedPanel = advancedPanel;
        this.countUsingAdvancedFilters();
      },
      toggle: function() {
        this.advanced = !this.advanced;
      },
      countUsingAdvancedFilters: function() {
        this.usingAdvancedFiltersCount =
          _(this.advancedPanel.querySelectorAll('input, select'))
          .filter(el => (el.dataset.filled === 'true'))
          .map(el => ((el.name || el.id)))
          .uniq()
          .value()
          .length;
      }
    }
  }

  if (typeof window !== "undefined") {
    window.advancedSearcheableApp = advancedSearcheableApp;
  }

  return advancedSearcheableApp;
})();
