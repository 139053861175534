export default function (drawerContentId) {
  return {
    title: 'Panel title',
    width: null,
    opened: false,
    dismissOnAway: true,
    open: function (event) {
      const drawerContent = document.getElementById(drawerContentId)

      let { title, dismiss_on_away, template, callback, width } = event.detail

      this.title = title || 'Panel title'
      this.width = width || 'max-w-2xl'
      this.dismissOnAway = dismiss_on_away !== undefined ? dismiss_on_away : true

      const templateTag = template && document.getElementById(template)

      if (templateTag) {
        drawerContent.innerHTML = templateTag.innerHTML
      }

      if (callback) callback()

      this.opened = true
    },
    dismiss: function () {
      this.opened = false

      document.getElementById('drawer_app').removeAttribute('src')
    }
  }
}
