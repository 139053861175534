import consumer from '../channels/consumer'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

(function () {
  const notificationsApp = function (subscriberIds, notifications, unreadCount) {
    let subscriptions = {};

    const audio = new Audio('/audios/notification_sound.mp3')

    function subscribe(component) {
      subscriberIds.forEach((subscriberId) => {
        subscriptions[subscriberId] = subscribeToChannel(component, subscriberId)
      })
    }

    function subscribeToChannel(component, subscriberId) {
      const subscription =
        consumer
          .subscriptions
          .create({ channel: "Notifications::NotificationsChannel", subscriber_id: subscriberId }, {
            received(data) {
              component.notifications.unshift(data.message)
              component.unreadCount = data.unread_count
              audio.play()
            }
          })

      return subscription
    }

    TimeAgo.addLocale(en)
    const timeAgo = new TimeAgo('en-US')

    return {
      init: function($refs) {
        if ($refs) { $refs.notificationsContainer.style.height = `${window.innerHeight - 200}px` }
        subscribe(this)
      },
      open: false,
      tab: 'viewAll',
      notifications,
      unreadCount,
      notificationClicked: function (notification) {
        const subscription = subscriptions[notification.subscriber.id]
        subscription.perform('mark_as_read', { message_id: notification.id })
        notification.read_at = new Date()
        this.unreadCount -= 1

        if (!notification.compiled_message?.link) return

        setTimeout(() => {
          Turbo.visit(notification.compiled_message?.link)
        }, 100)
      },
      markAllAsRead: function () {
        Object.values(subscriptions).forEach((subscription) => {
          subscription.perform('mark_all_as_read')
        })

        this.notifications.forEach((notification) => {
          notification.read_at = new Date()
        })

        this.unreadCount = 0
      },
      timeAgo: function (time) {
        return timeAgo.format(new Date(time))
      }
    }
  }

  if (typeof window !== "undefined") {
    window.notificationsApp = notificationsApp;
  }

  return notificationsApp;
})();
